import React, { createContext, useContext, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

type SnackbarType = {
  message: string;
  severity?: "success" | "error" | "warning" | "info";
};

type SnackbarContextType = {
  setSnackbar: (snackbar: SnackbarType) => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [snackbar, setSnackbar] = useState<SnackbarType | null>(null);

  const handleClose = () => setSnackbar(null);

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {children}
      <Snackbar
        open={Boolean(snackbar)}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={snackbar?.severity || "info"} onClose={handleClose}>
          {snackbar?.message || ""}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context)
    throw new Error("useSnackbar must be used within SnackbarProvider");
  return context;
};
